.input-sheet {
    width: 250px;
    height: 25px;
    box-shadow: 5px 5px 5px 5px rgba(128, 128, 128, 0.37);
    border-radius: 20px;
    border: none;
    outline: none;
    left: 370px;
    top: 7px;
    padding-left: 15px;
}


.btn {
    outline: none;
    width: 100px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: default;
    background-color: rgba(102, 51, 153, 0.55);
    margin-top: 20px;
    color: white;
    border-radius: 20px;
    margin-bottom: 25px;
}

.logo-img {
    margin-top: 25px;
    width: 112px;
    height: 105px;
    background: -webkit-image-set(
            url("../../../public/images/Logo.png") 1x,
            url("../../../public/images/LogoX2.png") 2x,
            url("../../../public/images/LogoX3.png") 3x
    ) no-repeat;
}

.input-holder {
    display: flex;
    margin-top: 30px;
}

.ml {
    margin-left: 15px;
}

.mt {
    margin-top: 30px;
}

@media only screen and (max-width: 576px) {
    .rows {
        flex-direction: column;
        margin-left: 0;
        margin-top: 30px;
    }
}
